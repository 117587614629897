import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../General/DataTable';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { COLUMNS } from './Columns'
import { handleApiError } from '../General/HandleApiError';
import {getInvitationApprovalReport } from '@/services/api/report.service';
import Popup from 'reactjs-popup';
import styles from './InvitationApprovalReport.module.scss'
import { approveInvitation, dismissInvitation } from '@/services/api/userdata.service';



const InvitationApprovalReport = () => {
    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('');
    const [reportResult, setReportResult] = useState([]);
    const [openInvitationApprovalModal, setOpenInvitationApprovalModal] = useState(false)
    const [openCancelInvitationModal, setOpenCancelInvitationModal] = useState(false)
    const [userRowData, setUserRowData ] = useState({})

    function onApproveInvitation(row){
        setUserRowData(row)
        setOpenInvitationApprovalModal(true)

    }
    function onDismissInvitation(row){
        setUserRowData(row)
        setOpenCancelInvitationModal(true)

    }

    const reportColumns = [
        ...COLUMNS,
        {
            Header: 'Actions',
            accessor: (row) => row,
            id: 'actions',
            className: generalStyles.tableCell,
            disableFilters: true,
            Cell: ({ value: row }) => {
                return (
                    <>
                        {row.invitationApprovalStatus === 'pending' ? (
                            <div className={generalStyles.row}>
                                {/* {setUserRowData(row.original)} */}
                                <button
                                    className={generalStyles.submitButton}
                                    onClick={() => onApproveInvitation(row)}
                                >
                                    Approve
                                </button>
                                <button
                                    className={generalStyles.submitButton}
                                    onClick={() => onDismissInvitation(row)}
                                >
                                    Dismiss
                                </button>
                            </div>
                        ) : (
                            <></>
                        )
                        } 
                    </>
                );
            }
        }
    ]
    async function loadInvitationApprovalReport() {
        setReportApiStatus(API_STATES.loading);
        const result = await getInvitationApprovalReport();
        switch (result.status) {
            case API_STATES.success:
                setReportApiStatus(API_STATES.success);
                setReportResult(result.data);
                break;
            case API_STATES.error:
                handleApiError(result.error, setReportApiStatus, setReportApiError);
                break;
            default:
                setReportApiStatus(API_STATES.none);
                setReportApiError('');
        }
    }

    async function onConfirmApproveInvitation(userRowData) {
        setReportApiStatus(API_STATES.loading);
        const result = await approveInvitation(userRowData.userEmail, userRowData.nsCompanyId)
        if (result.status === API_STATES.success) {
            setOpenInvitationApprovalModal(false)
            loadInvitationApprovalReport()
        } else {
            handleApiError(
                result.error,
                setReportApiStatus,
                setReportApiError
            );
            setTimeout(() => setReportApiStatus(API_STATES.none), 6000)
        }
    }

    async function onConfirmCancelInvitation(userRowData) {
        setReportApiStatus(API_STATES.loading);
        const result = await dismissInvitation(userRowData.userEmail, userRowData.nsCompanyId);
        if (result.status === API_STATES.success) {
            setOpenCancelInvitationModal(false)
            loadInvitationApprovalReport()
        } else {
            handleApiError(
                result.error,
                setReportApiStatus,
                setReportApiError
            );
            setTimeout(() => setReportApiStatus(API_STATES.none), 6000)
        }
    }

    useEffect(() => {
        loadInvitationApprovalReport();
    }, []);

    return (
        <>
            <h1>Invitation Approval Report</h1>
            <hr />
            <div data-test="generate-report-status">
                {reportApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {reportApiError}</p>
                ) : reportApiStatus === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                ) : (
                    reportApiStatus === API_STATES.success && (
                        <DataTable columns={reportColumns} data={reportResult} withDownload downloadFileName={'Domain_Invitation_Report'} />
                    )
                )}
            </div>
            {openInvitationApprovalModal &&
                <Popup
                    open={openInvitationApprovalModal}
                    onClose={() => setOpenInvitationApprovalModal(false)
                    }
                    modal
                >
                    {() => (
                        <div className={styles.modalContainer}>
                            <b>Do you wish to apporove this invitation ?</b>
                            <br />
                            <div className={generalStyles.row}>
                                <button className={generalStyles.confirmOperationButton} onClick={()=>onConfirmApproveInvitation(userRowData)}>Submit</button>
                                <button className={generalStyles.cancelOperationButton} onClick={() => setOpenInvitationApprovalModal(false)}>Cancel</button>
                            </div>
                        </div>)
                    }
                </Popup>
            }
            {openCancelInvitationModal &&
                <Popup
                    className={styles.modalContainer}
                    open={openCancelInvitationModal}
                    onClose={() => setOpenCancelInvitationModal(false)
                    }
                    modal
                >
                    {() => (
                        <div className={styles.modalContainer}>
                            <b>Are you sure, you wish to dismiss this invitation ?</b>
                            <br />
                            <div className={generalStyles.row}>
                                <button className={generalStyles.confirmOperationButton} onClick={()=>onConfirmCancelInvitation(userRowData)}>Submit</button>
                                <button className={generalStyles.cancelOperationButton} onClick={() => setOpenCancelInvitationModal(false)}>Cancel</button>
                            </div>
                        </div>)
                    }
                </Popup>

            }
        </>
    )
}

export default InvitationApprovalReport